import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { getREF } from '@uz/unitz-providers/RefProvider';

import _ from 'lodash';
import moment from 'moment';
import { formatDuration } from '@vl/mod-utils/datetime';
import { Button } from '@uz/unitz-components-web/Button';
import Link from '@uz/unitz-components-web/Link';

const COUNT_DOWN_TIMER_END_SECOND = 5 * 60;

export const CountDownTimerAction = ({ room, interval = 1000, ...rest }) => {
  const $start_at = React.useMemo(() => moment(room.start_at), [room.start_at]);
  const $end_at = React.useMemo(() => moment(room.end_at), [room.end_at]);
  const [counter, $counter] = React.useState(0);
  const ref = React.useRef({});
  _.assign(ref.current, {
    $start_at,
    $counter,
    counter,
    interval,
  });
  const $start_duration = moment.duration($start_at.diff(moment()));

  React.useEffect(() => {
    ref.current.timer = setInterval(() => {
      ref.current.$counter(ref.current.counter + 1);
    }, ref.current.interval);
    return () => {
      if (ref.current.timer) {
        clearInterval(ref.current.timer);
      }
      ref.current = {};
    };
  }, []);

  const $now = moment();
  const handleJoinRoom = async () => {
    // const join_url = _.get(room, 'join_url');
    // if (join_url) {
    //   window.open(join_url, '_blank');
    // }
    const videoSdkModelRef = getREF().getRef('videoSdkModelRef');
    if (videoSdkModelRef?.connectRoom) {
      await videoSdkModelRef.connectRoom();
    }
  };

  if ($start_duration.asSeconds() > COUNT_DOWN_TIMER_END_SECOND) {
    return (
      <DIV>
        <Button
          className="bg-red block mx-auto w-40"
          type="primary"
          block
          size="small"
          {...rest}
          onClick={handleJoinRoom}
        >
          {ctx.apply('i18n.t', 'AdvisorCourse.roomActions.startIn', {
            count: formatDuration($start_duration),
          })}
        </Button>
      </DIV>
    );
  }

  if ($start_duration.asSeconds() <= COUNT_DOWN_TIMER_END_SECOND && $start_at.isAfter($now)) {
    return (
      <DIV>
        <Button
          className="bg-red block mx-auto w-40"
          type="primary"
          block
          size="small"
          {...rest}
          onClick={handleJoinRoom}
        >
          {ctx.apply('i18n.t', 'AdvisorCourse.roomActions.startIn', {
            count: formatDuration($start_duration),
          })}
        </Button>
      </DIV>
    );
  }

  if ($now.isBetween($start_at, $end_at)) {
    return (
      <DIV>
        <Button
          className="bg-red block mx-auto w-40"
          type="primary"
          block
          size="small"
          {...rest}
          onClick={handleJoinRoom}
        >
          {ctx.apply('i18n.t', 'AdvisorCourse.roomActions.endIn', {
            count: formatDuration(moment.duration($end_at.diff(moment()))),
          })}
        </Button>
      </DIV>
    );
  }

  if ($end_at.isBefore($now)) {
    return (
      <DIV>
        <Button
          className="bg-red block mx-auto w-40"
          type="primary"
          block
          size="small"
          name="inkBg2"
          {...rest}
          onClick={handleJoinRoom}
        >
          {ctx.apply('i18n.t', 'AdvisorCourse.roomStatus.completed')}
        </Button>
      </DIV>
    );
  }
  return (
    <DIV>
      <Button
        className="bg-red block mx-auto w-40"
        type="primary"
        block
        size="small"
        {...rest}
        onClick={handleJoinRoom}
      >
        {ctx.apply('i18n.t', 'AdvisorCourse.roomActions.enter', {})}
      </Button>
    </DIV>
  );
};

export const View8 = (props) => {
  return (
    <DIV>
      <CountDownTimerAction {...props} room={ctx.get('@item')} />
    </DIV>
  );
};

export default displayName(View8);
