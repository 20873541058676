import { getREF } from '@uz/unitz-providers/RefProvider';
import _ from 'lodash';
import { waitUntil } from '@vl/mod-utils/waitUntil';
import elementResizeDetectorMaker from 'element-resize-detector';

export const getOwnerId = () => {
  const authModel = getREF().getRef('authModel');
  if (authModel) {
    return authModel.getUserId();
  }
  return '';
};

export const parseJSON = (data) => {
  let rtn = {};
  try {
    console.log('data.length', _.get(data, 'length'));
    rtn = JSON.parse(data);
  } catch (err) {
    console.log('parse json error', _.get(data, 'length'), data);
  }
  return rtn;
};

export const toCanvasPayload = (canvas) => {
  const data = canvas.toJSON();
  const rtn = {};
  const userId = getOwnerId();
  let objects = _.get(data, 'objects', []);
  for (const obj of objects) {
    const { id, owner_id, isLocal } = obj;
    if (owner_id === userId && !isLocal) {
      // process obj by type
      if (_.get(obj, 'type') === 'i-text' && rtn[id] && !obj.text) {
        continue;
      }
      rtn[id] = obj;
    }
  }
  return JSON.stringify(rtn);
};

export const addObjectsToCanvas = (canvas, objects) => {
  const { fabric } = window;
  const userId = getOwnerId();

  fabric.util.enlivenObjects(objects, (objects) => {
    const origRenderOnAddRemove = canvas.renderOnAddRemove;
    canvas.renderOnAddRemove = false;
    objects.forEach((item) => {
      // object permission
      if (item.owner_id !== userId) {
        item.set({
          lockMovementX: true,
          lockMovementY: true,
          hasControls: false,
          hasBorders: false,
          viewOnly: true,
          editable: false,
          hoverCursor: 'pointer',
        });
      }
      return canvas.add(item);
    });
    canvas.renderOnAddRemove = origRenderOnAddRemove;
    canvas.renderAll();
  });
};

export const fromCanvasPayload = (canvas, payloadStr) => {
  const json = canvas.toJSON();
  const objects = [];
  const payload = parseJSON(payloadStr);
  _.map(payload, (obj, key) => {
    if (key) {
      // process obj by type
      if (_.get(obj, 'type') === 'i-text') {
        _.update(obj, 'text', (val) => val || '');
      }
      obj && objects.push(obj);
    }
  });
  json.objects = objects;
  addObjectsToCanvas(canvas, json.objects);
  return canvas;
};

export const syncObjectsToCanvas = (canvas, objects, userId) => {
  const { fabric } = window;
  const myId = getOwnerId();

  const origRenderOnAddRemove = canvas.renderOnAddRemove;
  canvas.renderOnAddRemove = false;
  const currentObjects = canvas.getObjects();

  fabric.util.enlivenObjects(objects, (newObjects) => {
    const objByOwnerId = _.groupBy(newObjects, 'owner_id');
    const objById = _.keyBy(newObjects, 'id');
    for (let item of currentObjects) {
      if (item.owner_id && objByOwnerId[item.owner_id] && !objById[item.id]) {
        // check for removal
        canvas.remove(item);
      } else if (item.owner_id && objByOwnerId[item.owner_id] && objById[item.id]) {
        // check for updating
        const obj = objById[item.id];
        item.set(obj);
        item.setCoords();
        // object permission
        if (item.owner_id !== myId) {
          item.set({
            lockMovementX: true,
            lockMovementY: true,
            hasControls: false,
            hasBorders: false,
            viewOnly: true,
            editable: false,
            hoverCursor: 'pointer',
          });
        }
        _.unset(objById, item.id);
      } else if (!_.get(newObjects, 'length') && item.owner_id === userId) {
        // check for wipeout
        canvas.remove(item);
      }
    }

    // check for adding
    _.map(objById, (item) => {
      // object permission
      if (item.owner_id !== myId) {
        item.set({
          lockMovementX: true,
          lockMovementY: true,
          hasControls: false,
          hasBorders: false,
          viewOnly: true,
          editable: false,
          hoverCursor: 'pointer',
        });
      }
      return canvas.add(item);
    });
  });
  canvas.renderOnAddRemove = origRenderOnAddRemove;
  canvas.renderAll();
};

export const syncCanvasPayload = (canvas, payloadStr, userId) => {
  const json = canvas.toJSON();
  const objects = [];
  const payload = parseJSON(payloadStr);
  _.map(payload, (obj, key) => {
    if (key) {
      // process obj by type
      if (_.get(obj, 'type') === 'i-text') {
        _.update(obj, 'text', (val) => val || '');
      }
      obj && objects.push(obj);
    }
  });
  json.objects = objects;
  syncObjectsToCanvas(canvas, json.objects, userId);
  return canvas;
};

export const toObjectPayload = (obj) => {
  let rtn;
  try {
    rtn = JSON.stringify(obj);
  } catch (err) {
    console.log(err);
  }
  return rtn;
};

export const fromObjectPayload = (payloadStr) => {
  let data;
  try {
    data = JSON.parse(payloadStr);
  } catch (err) {}
  return data;
};

export const ensureHtmlCanvas = async (targetId) => {
  const targetEle = document.getElementById(targetId);
  const canvasId = `canvas_${targetId}`;
  let canvas;
  if (targetEle) {
    canvas = targetEle.querySelector(`#${canvasId}`);
    if (!canvas) {
      const targetEleRect = await waitUntil(
        () => targetEle.getBoundingClientRect(),
        (item) => {
          return _.every(_.values(_.pick(item, ['width', 'height'])));
        },
        1000
      );

      canvas = document.createElement('canvas');
      canvas.id = canvasId;
      canvas.width = window.innerWidth;
      canvas.height = targetEleRect.height;
      // A0 size in pixel
      // canvas.width = 14043 / 8;
      // canvas.height = 9933 / 4;
      canvas.style.zIndex = 8;
      canvas.style.position = 'absolute';
      // canvas.style.border = '1px solid';
      canvas.style.top = '0px';
      canvas.style.left = '0px';
      canvas.style.right = '0px';
      canvas.style.bottom = '0px';
      targetEle.appendChild(canvas);

      // collabCanvas
      const canvasCollab = document.createElement('canvas');
      canvasCollab.id = `${canvasId}_collab`;
      // canvas.width = targetEleRect.width;
      // canvas.height = targetEleRect.height;
      // A0 size in pixel
      canvasCollab.width = canvas.width;
      canvasCollab.height = canvas.height;
      canvasCollab.style.zIndex = canvas.style.zIndex + 1;
      canvasCollab.style.position = canvas.style.position;
      canvasCollab.style.top = canvas.style.top;
      canvasCollab.style.left = canvas.style.left;
      canvasCollab.style.right = canvas.style.right;
      canvasCollab.style.bottom = canvas.style.bottom;
      canvasCollab.style['pointer-events'] = 'none';
      targetEle.appendChild(canvasCollab);
    }
  }
  return canvas;
};

export const configFitZoom = (targetId, canvas) => {
  const { fabric } = window;
  const fitTarget = document.getElementById(targetId);
  const userId = getOwnerId();
  if (fitTarget) {
    const erd = elementResizeDetectorMaker();
    erd.listenTo(fitTarget, () => {
      const img = fitTarget.querySelector('img');
      // fit to img
      if (img) {
        const { naturalWidth, naturalHeight, clientWidth } = img;
        if (naturalWidth && naturalHeight) {
          const zoom = clientWidth / naturalWidth;
          canvas && canvas.setZoom(zoom);
        }
      }
      // fit to iframe
      const iframe = fitTarget.querySelector('iframe');
      if (iframe) {
        const dispatchSyncEvent = (item) => {
          let payload = _.get(item, 'payload') || '';
          payload = JSON.parse(payload);
          const id = _.get(item, 'id');
          const action = _.get(item, 'action');
          if (['viewer:sync'].includes(action) && id === userId) {
            // update canvas zoom and pan
            if (canvas) {
              const scale = parseInt(_.get(payload, '_location.scale')) || 100;
              const offsetX = parseInt(_.get(payload, 'rect.x', 0)) || 0;
              // const offsetY = parseInt(_.get(payload, 'rect.y', 0)) || 0;
              const offsetY = 1;
              const scrollLeft = _.get(payload, 'scrollLeft', 0) || 0;
              const scrollTop = _.get(payload, 'scrollTop', 0) || 0;
              const zoom = scale / 100;
              if (zoom !== canvas.getZoom()) {
                canvas.setZoom(zoom);
              }
              // const deltaMarginLeft = 9;
              // const deltaMarginLeft = 4;
              let containerMarginLeft = scrollLeft + _.get(payload, 'offset.left');
              containerMarginLeft = containerMarginLeft || 0;
              let containerMarginTop = scrollTop + _.get(payload, 'offset.top');
              containerMarginTop = containerMarginTop || 0;

              const containerWidth = _.get(payload, 'pageRect.width');

              let delta = new fabric.Point(
                // scrollLeft - offsetX - deltaMarginLeft,
                scrollLeft,
                // scrollTop - offsetY - deltaMarginTop
                scrollTop
              );

              canvas.absolutePan(delta);
              const parentEle = canvas.htmlEle.parentElement;

              if (parentEle) {
                canvas.htmlEle.style.width = `${containerWidth}px`;
                canvas.htmlEle.width = containerWidth;

                // canvas.width = containerWidth;
                parentEle.style.left = `${containerMarginLeft}px`;
                parentEle.style.top = `${containerMarginTop}px`;
                parentEle.style.width = `${containerWidth}px`;
                // canvas.collab.width = containerWidth;
                canvas.collab.lowerCanvasEl.style.left = `${containerMarginLeft}px`;
                canvas.collab.lowerCanvasEl.style.top = `${containerMarginTop}px`;
                canvas.collab.lowerCanvasEl.style.width = `${containerWidth}px`;
                canvas.collab.lowerCanvasEl.width = containerWidth;

                // fix dpr retina
                const dpr = window.devicePixelRatio || 1;
                if (dpr !== 1) {
                  canvas.htmlEle.width = containerWidth * dpr;
                  canvas.htmlEle.getContext('2d').scale(dpr, dpr);
                  canvas.collab.lowerCanvasEl.width = containerWidth * dpr;
                  canvas.collab.lowerCanvasEl.getContext('2d').scale(dpr, dpr);
                }
              }
            }
          }
        };

        iframe.addEventListener('viewer_sync', (evt) => {
          const item = _.get(evt, 'detail.data', {});
          dispatchSyncEvent(item);
        });
      }
      // fit to video?
    });
    return () => {
      erd.removeAllListeners(fitTarget);
    };
  }
};

// export const ensureTarget = (target) => {
//   if (!target.id) {
//     target.set({
//       id: genObjectId(),
//     });
//   }
//   if (!_.has(target, 'owner_id')) {
//     target.set({
//       owner_id: getOwnerId(),
//     });
//   }
// }
