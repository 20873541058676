import React from 'react';
import _ from 'lodash';
import cx from 'classnames';

import MessageModelRenderer from '@uz/unitz-models/MessageModel/renderer';
import MessageStore from '@uz/unitz-tool-components/Message/Store';
import MessageHeader from '@uz/unitz-tool-components/Message/Components/MessageHeader';
import MessageTimer from '@uz/unitz-tool-components/Message/Components/MessageTimer';
import MessageAuthor from '@uz/unitz-tool-components/Message/Components/MessageAuthor';
import MessageStatus from '@uz/unitz-tool-components/Message/Components/MessageStatus';
import MessageReaction from '@uz/unitz-tool-components/Message/Components/MessageReaction';
import MessageReplyInputIndicator from '@uz/unitz-tool-components/Message/Components/MessageReplyInputIndicator';
import MessageReply from '@uz/unitz-tool-components/Message/Components/MessageReply';

export const MessageItemMark = React.memo(({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  const containerRef = React.useRef();
  const [isEditing] = MessageStore.useMessageState(message_id, 'editing', false);
  const [isReplying] = MessageStore.useMessageState(message_id, 'replying', false);
  return (
    <div
      ref={containerRef}
      className={cx('px-4 py-2 hover:bg-brandb-lightest relative', { 'bg-blue-100': isEditing || isReplying })}
    >
      <div className="table-cell align-top">
        <MessageHeader message_id={message_id} />
      </div>
      <div className="table-cell align-top pl-3">
        <div className="flex items-center ">
          <MessageAuthor message_id={message_id} />
          <MessageTimer message_id={message_id} />
        </div>
        <div>
          {_.get(message, 'deleted') ? (
            <MessageStatus.Removed />
          ) : (
            <>
              {MessageModelRenderer.renderMessage()(message)}
              <MessageStatus.Edited message_id={message_id} />
            </>
          )}
        </div>
      </div>

      <div style={{ marginLeft: 30 }}>
        <MessageReaction message_id={message_id} />
        <MessageReply message_id={message_id} />
        <MessageReplyInputIndicator message_id={message_id} />
      </div>
    </div>
  );
});

export default MessageItemMark;
