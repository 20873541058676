import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import MessageStore from '@uz/unitz-tool-components/Message/Store';

// import { Divider } from 'antd';

export const MessageStartDay = React.memo(({ message_id }) => {
  const { message } = MessageStore.use(message_id);
  return (
    <div style={{ color: '#99AFC2' }} className="py-4 text-center text-xs">
      {moment(message.created_at).format('YYYY/MM/DD')}
    </div>
  );
});

export default MessageStartDay;
