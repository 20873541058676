import React from 'react';

import _ from 'lodash';
import { Virtuoso } from 'react-virtuoso';

import AutoSizer from '@uz/unitz-components-web/AutoSizer';

import messageMap from '@uz/unitz-tool-components/Message/Store';
import MessageItem from '@uz/unitz-tool-components/Message/Item';
import styled from 'styled-components';

const Message = {};
const CHUNK_SIZE = 20;

const hashChunk = (chunk) => _.reduce(chunk, (acc, { id, updated_at }) => `${acc}${id}${updated_at}`, '');
const hashChunkShake = (chunk) =>
  _.reduce(
    chunk,
    (acc, item) => {
      const { id, updated_at } = item;
      messageMap.set(item.id, item);
      return `${acc}${id}${updated_at}`;
    },
    ''
  );

const StyledList = styled(Virtuoso)`
  [data-test-id='virtuoso-item-list'] {
    padding-top: 3px !important;
  }
`;

Message.MessageList = React.memo(
  ({ messages, lastItemRef, lastItem }) => {
    const ref = React.useRef({});
    Object.assign(ref.current, { messages, lastItemRef, lastItem });
    const itemContent = React.useCallback(
      (index) => {
        const { messages } = ref.current;
        const item = messages[index];
        return (
          <MessageItem
            key={`${item.id}`}
            message_id={item.id}
            lastItemRef={item.id === _.get(lastItem, 'id') ? lastItemRef : null}
          />
        );
      },
      [ref]
    );
    const rangeChanged = React.useCallback(
      ({ startIndex, endIndex }) => {
        // console.log('{ startIndex, endIndex }', { startIndex, endIndex });
        return { startIndex, endIndex };
      },
      [ref]
    );
    return (
      <AutoSizer>
        {({ height, width }) => {
          return (
            <StyledList
              style={{
                width: `${width}px`,
                height: `${height}px`,
              }}
              totalCount={messages.length}
              itemContent={itemContent}
              rangeChanged={rangeChanged}
              initialTopMostItemIndex={messages.length - 1}
            />
          );
        }}
      </AutoSizer>
    );
    // const messageChunks = _.chunk(messages, CHUNK_SIZE);
    // return (
    //   <React.Fragment>
    //     {_.map(messageChunks, (chunk) => {
    //       if (messageChunks.length !== 1 && chunk.length === CHUNK_SIZE) {
    //         const key = hashChunk(chunk);
    //         return <Message.MessageList key={`${key}`} messages={[...chunk]} chunk />;
    //       }
    //       return _.map(chunk, (item) => {
    //         return (
    //           <MessageItem
    //             key={`${item.id}`}
    //             message_id={item.id}
    //             lastItemRef={item.id === _.get(lastItem, 'id') ? lastItemRef : null}
    //           />
    //         );
    //       });
    //     })}
    //   </React.Fragment>
    // );
  },
  (prevProps, nextProps) => {
    let nextHash;
    if (!nextProps.chunk) {
      nextHash = hashChunkShake(nextProps.messages);
    }
    if (prevProps.messages.length !== nextProps.messages.length) {
      return false;
    }
    const prevHash = hashChunk(prevProps.messages);
    nextHash = nextHash || hashChunk(nextProps.messages);
    return prevHash === nextHash;
  }
);

export default Message.MessageList;
