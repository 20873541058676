import _ from 'lodash';
import Glightbox from '@uz/unitz-components-web/Glightbox';
import { projectSize } from '@vl/mod-utils/layout';

const BlockVideoBlot = {
  createHTML(value, options) {
    let { width, height, id, src, name } = _.pick(value, ['width', 'height', 'id', 'src', 'name']);
    const size = projectSize({ width, height }, { width: _.get(options, 'width', 320) });
    const sizeStyle = `width: ${size.width}px; height:${size.height}px;`;
    const sizeProps = `width="${size.width}" height="${size.height}"`;
    src = `${src.replace('watch.', 'iframe.')}`;

    const wrapperStyle = 'position: relative; overflow: hidden; display: inline-block;';
    const videoStyle = `${sizeStyle} border-radius: 8px; object-fit: cover;`;
    const videoFrame = `<iframe src="${src}" ${sizeProps} style="${videoStyle}border: none;" allow="accelerometer; gyroscope; encrypted-media;" allowfullscreen="true" id="${`stream-player-${id}`}">`;
    const videoInfo = `<div style="padding: 8px 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${name ||
      src}</div>`;
    const html = `<a class="glightbox" href="${src}" style="${wrapperStyle}">${videoInfo}${videoFrame}</a>`;
    setTimeout(() => {
      const instance = Glightbox.getInstance();
      if (instance) {
        instance.insertSlide({
          href: _.get(value, 'src'),
          type: 'video',
          width: _.get(value, 'width'),
        });
      }
      Glightbox.apply();
      // Glightbox.applyPlayer(id);
    }, 500);

    return html;
  },

  renderCustomWith(customOp) {
    const { value } = customOp.insert;
    if (!value) return '';
    const id = _.get(value, 'id');
    return `<div id="${id}" class="${
      BlockVideoBlot.className
    }" style="position: relative;">${BlockVideoBlot.createHTML(value, { height: 164 })}</div>`;
  },

  register(registerRender) {
    registerRender(BlockVideoBlot.blotName, BlockVideoBlot.renderCustomWith);
  },
};

BlockVideoBlot.blotName = 'block-video';
BlockVideoBlot.className = 'block-video';
BlockVideoBlot.tagName = 'div';

export default BlockVideoBlot;
