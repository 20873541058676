exports.projectSize = (from, to) => {
  if (to.width && from.width) {
    return {
      ...to,
      height: Math.ceil((to.width * from.height) / from.width),
    };
  }

  if (to.height && from.height) {
    return {
      ...to,
      width: Math.ceil((to.height * from.width) / from.height),
    };
  }
  return { ...to };
};
