import React from 'react';
import { Dropdown, Menu } from 'antd';
import gstyles from '@vl/gstyles';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';

import _ from 'lodash';
import { MessageItemMark } from '@uz/unitz-tool-components/Message/Item';

const MessageMenu = () => {
  return (
    <DIV>
      {!!_.keys(ctx.get('messageModel.message_marks')).length && (
        <div className="flex-1 border-b border-gray-200 px-3 pb-2 space-y-2">
          {_.map(ctx.get('messageModel.message_marks'), (marks, mark_title) => {
            return (
              <Dropdown
                key={mark_title}
                trigger="click"
                autoAdjustOverflow={false}
                placement="bottomLeft"
                destroyPopupOnHide
                getPopupContainer={() => {
                  const messageContainerRef = ctx.apply('REF.getRef', 'messageContainerRef');
                  return messageContainerRef?.current?.containerRef?.current || document.body;
                }}
                overlayClassName="left-4- right-4- rounded-md"
                overlay={
                  <Menu className="w-full">
                    {_.map(marks, (mark) => {
                      return (
                        <Menu.Item key={_.get(mark, 'id')} className="space-x-2">
                          <MessageItemMark message_id={_.get(mark, 'message_id')} />
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
              >
                <mark className="message-mark" title={mark_title}>
                  {mark_title}
                </mark>
              </Dropdown>
            );
          })}
        </div>
      )}
    </DIV>
  );
};

export default MessageMenu;
