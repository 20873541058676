import _ from 'lodash';
import Glightbox from '@uz/unitz-components-web/Glightbox';
import { projectSize } from '@vl/mod-utils/layout';
import { createImageUrl } from '@vl/mod-utils/file';

const BlockImageBlot = {
  createHTML(value, options) {
    let { width, height, id, src, name } = _.pick(value, ['width', 'height', 'id', 'src', 'name']);
    const size = projectSize({ width, height }, { height: _.get(options, 'height', 62) });
    const sizeStyle = `width: ${size.width}px; height:${size.height}px;`;
    let srcThumb = _.get(createImageUrl({ ...value, ...size, src }), 'src');
    const imageRefetch = 'onerror="window.retryImageUrl && window.retryImageUrl(this)"';
    const wrapperStyle = `${sizeStyle} overflow: hidden; background: #FFFFFF;border: 1px solid #BFCDD9; border-radius: 8px; display: inline-block;`;
    const html = `<a class="glightbox" href="${src}" data-title="${name}" style="${wrapperStyle}"><img id="${id}" ${imageRefetch} src="${srcThumb}" alt="${name}" style="${sizeStyle}"/></a>`;

    setTimeout(() => {
      const instance = Glightbox.getInstance();
      if (instance) {
        instance.insertSlide({
          href: _.get(value, 'src'),
          type: 'image',
          title: _.get(value, 'name'),
        });
      }
      Glightbox.apply();
    }, 500);

    return html;
  },

  renderCustomWith(customOp) {
    const { value } = customOp.insert;
    if (!value) return '';
    const id = _.get(value, 'id');
    const html = `<div id="${id}" class="${
      BlockImageBlot.className
    }" style="position: relative;">${BlockImageBlot.createHTML(value, { height: 120 })}</div>`;

    return html;
  },

  register(registerRender) {
    registerRender(BlockImageBlot.blotName, BlockImageBlot.renderCustomWith);
  },
};

BlockImageBlot.blotName = 'block-image';
BlockImageBlot.className = 'block-image';
BlockImageBlot.tagName = 'div';

export default BlockImageBlot;
