import _ from 'lodash';
import UserIdentity from '@uz/unitz-components-web/UserIdentity';

const backgroundColorMap = {
  '#E05932': '#F5BFB1',
  '#F6C844': '#FCEA89',
  '#4DA560': '#A5E09D',
  '#7199ED': '#B6D0FB',
  '#6C50F6': '#E3C1FB',
  '#C67F3E': '#E4C38F',
  '#545454': '#CFD3D9',
  '#FFFFFF': '#F8F8F8',
};

const config = (fabric) => {
  fabric.StickyNote = fabric.util.createClass(fabric.Rect, {
    type: 'StickyNote',
    text: '',
    paddingX: 0,
    paddingY: 0,
    showAuthor: true,
    _prevObjectStacking: null,
    _prevAngle: 0,

    recalcTextPosition() {
      const fill = _.get(backgroundColorMap, _.get(this, 'colorType'), _.get(this, 'colorType')) || '#F5BFB1';
      const sin = Math.sin(fabric.util.degreesToRadians(this.angle));
      const cos = Math.cos(fabric.util.degreesToRadians(this.angle));
      const newTop = sin * this.paddingX + cos * this.paddingY;
      const newLeft = cos * this.paddingX - sin * this.paddingY;
      const width = this.width - 2 * this.paddingX;
      const height = this.height - 2 * this.paddingY;
      const rectLeftTop = this.getPointByOrigin('left', 'top');

      const textProps = {
        left: rectLeftTop.x + newLeft,
        top: rectLeftTop.y + newTop,
        width,
        height,
        ..._.pick(this, ['strokeWidth', 'charSpacing', 'fontSize', 'textAlign', 'fontWeight', 'text']),
      };
      const textbox = this.getTextbox();

      textbox.set(textProps);
      textbox.setCoords();

      const authorTextHeight = 12;
      const lineHeight = 12;
      const paddingBottom = this.paddingY + lineHeight * 2 + authorTextHeight;
      const containerProps = {
        fill,
        stroke: fill,
      };
      if (textbox.height + paddingBottom >= this.height || this.height - textbox.height >= paddingBottom) {
        _.assign(containerProps, {
          height: Math.max(200, textbox.height + paddingBottom),
        });
      }
      this.set(containerProps);
      this.setCoords();

      const authorTextProps = {
        left: rectLeftTop.x + newLeft,
        top:
          rectLeftTop.y +
          sin * (this.width - this.paddingX - authorTextHeight) +
          cos * (this.height - this.paddingY - authorTextHeight),
        ...(this.showAuthor
          ? {
              opacity: 1,
            }
          : {
              opacity: 0,
            }),
      };
      const authorText = this.getAuthorText();
      authorText.set(authorTextProps);
      authorText.setCoords();
    },

    getCanvas(evt) {
      let rtn;
      rtn = _.get(evt, 'target.canvas');
      if (rtn) return rtn;
      return this.canvas;
    },
    getTextbox() {
      const canvas = this.getCanvas();
      let textbox = this.textbox;
      if (canvas) {
        textbox = _.find(canvas.getObjects(), { id: `${this.id}_1` }) || textbox;
      }
      return textbox;
    },
    getAuthorText() {
      const canvas = this.getCanvas();
      let authorText = this.authorText;
      if (canvas) {
        authorText = _.find(canvas.getObjects(), { id: `${this.id}_2` }) || authorText;
      }
      return authorText;
    },

    enterEditing() {
      if (this.textbox) {
        this.textbox.set({
          selectable: true,
          evented: true,
        });
        this.canvas && this.canvas.setActiveObject(this.textbox);
        this.textbox.enterEditing();
        this.set({
          selectable: false,
        });
      }
    },

    bringToFront() {
      if (this.canvas) {
        this.canvas?.bringToFront?.(this);
        this.textbox && this.canvas?.bringToFront?.(this.textbox);
        this.authorText && this.canvas?.bringToFront?.(this.authorText);
      }
    },

    toObject() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        text: `${_.get(this, 'textbox.text', '')}`,
        ..._.pick(this, [
          'paddingX',
          'paddingY',
          'strokeWidth',
          'charSpacing',
          'fontSize',
          'colorType',
          'textAlign',
          'showAuthor',
        ]),
      });
    },

    initialize(options, object) {
      options = options || object || {};
      this.callSuper('initialize', options);
      // this.lockUniScaling = true;
      this.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
      });
      const text = this.text || '';
      const textColor = '#333333';
      this.textbox = new fabric.Textbox(`${text}`, {
        ..._.pick(this, ['strokeWidth', 'charSpacing', 'fontSize', 'text', 'textAlign', 'fontWeight']),
        hasBorders: false,
        fill: textColor,
        stroke: textColor,
        selectable: false,
        evented: false,
        isLocal: true,
        id: `${this.id}_1`,
      });

      const authorText = '';
      const authorColor = '#7F7F7F';
      this.authorText = new fabric.Text(`${authorText}`, {
        fontSize: 12,
        charSpacing: 10,
        strokeWidth: 1,
        text: authorText,
        fill: authorColor,
        stroke: authorColor,
        selectable: false,
        evented: false,
        isLocal: true,
        id: `${this.id}_2`,
      });

      UserIdentity.getDisplayName(this.owner_id).then((val) => {
        this.authorText.set({
          text: `${val}`,
        });
      });

      const getCanvas = (evt) => {
        let rtn;
        rtn = _.get(evt, 'target.canvas');
        if (rtn) return rtn;
        return this.canvas;
      };

      this.recalcTextPosition();
      this.on('moving', () => {
        this.recalcTextPosition();
      });
      this.on('rotating', () => {
        this.textbox.rotate(this.textbox.angle + this.angle - this._prevAngle);
        this.authorText.rotate(this.authorText.angle + this.angle - this._prevAngle);
        this.recalcTextPosition();
        this._prevAngle = this.angle;
      });
      this.on('scaling', () => {
        this.recalcTextPosition();
      });
      this.on('added', function(evt) {
        const canvas = getCanvas(evt);
        if (!canvas) return;
        canvas.add(this.textbox);
        canvas.add(this.authorText);
      });
      this.on('removed', function(evt) {
        const canvas = getCanvas(evt);
        if (!canvas) return;
        canvas.remove(this.textbox);
        canvas.remove(this.authorText);
      });
      this.on('mousedown:before', function(evt) {
        const canvas = getCanvas(evt);
        if (!canvas) return;
        this._prevObjectStacking = canvas.preserveObjectStacking;
        canvas.preserveObjectStacking = true;
      });
      this.on('mousedblclick', () => {
        if (this.viewOnly) return;
        this.enterEditing();
      });
      // this.on('deselected', function(evt) {
      //   const canvas = getCanvas(evt);
      //   if (canvas) {
      //     // canvas.preserveObjectStacking = this._prevObjectStacking;
      //   }
      // });
      this.on('selected', function() {
        this.bringToFront();
        this.recalcTextPosition();
      });

      this.textbox.on('editing:exited', () => {
        this.textbox.selectable = false;
        this.textbox.evented = false;
        this.selectable = true;
      });
      this.textbox.on('changed', (evt) => {
        this.text = this.textbox.text;
        this.recalcTextPosition();
        const canvas = getCanvas(evt);
        canvas && canvas.fire('text:changed', { target: this });
      });

      this.on('modified', function() {
        this.recalcTextPosition();
      });
      this.on('object:modified', function() {
        this.recalcTextPosition();
      });
    },
  });

  fabric.StickyNote.fromObject = function(object, callback) {
    return fabric.Object._fromObject('StickyNote', object, callback, {});
  };
};

export default config;
