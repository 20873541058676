import React from 'react';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Dropdown, Tooltip, Upload } from 'antd';
import i18n from '@uz/mod-translations/web';
import displayName from '@vl/redata/displayName.macro';
import { useFormik } from 'formik';

import styled from 'styled-components';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useDisposerCleaner from '@vl/hooks/useDisposerCleaner';
import Combokeys from 'combokeys';
import { isActivateMode, isDeactivateMode } from '@uz/unitz-components-web/Fabric/helper';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';

import ColorInput from '../Inputs/Color';

const MenuItem = styled(Dropdown.Button)`
  .ant-btn-icon-only {
    width: 2px !important;
  }
  .ant-btn {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-color: transparent !important;
    border-radius: 8px !important;
    &:last-child {
      display: none;
    }
  }
`;

const Index = ({ canvas }) => {
  const [target, $target] = React.useState(() => {
    const { fabric } = window;
    const target = new fabric.Picture({
      text: '',
      imgSrc: '',
      owner_id: canvas.getOwnerId(),
      left: -10,
      top: -10,
      colorType: '#E05932',
      fill: '#F5BFB1',
      strokeWidth: 1,
      charSpacing: 10,
      fontSize: 16,
      paddingX: 10,
      paddingY: 10,
      width: 0,
      height: 0,
      selectable: false,
      isLocal: true,
      isToolbar: true,
      isToolbarActive: false,
      isPictureMode: false,
      showAuthor: false,
      opacity: 0,
    });
    canvas.add(target);
    return target;
  });
  const ref = React.useRef({ disposers: [] });

  const form = useFormik({
    initialValues: {
      imgSrc: [],
    }
  });
  _.assign(ref.current, {
    target,
    $target,
    canvas,
    form,
  });


  const forceUpdate = useForceUpdate();
  const disposerCleaner = useDisposerCleaner();
  const getVisible = React.useCallback(() => {
    return !!ref.current.target.isPictureMode;
  }, [ref.current.target.isPictureMode]);

  React.useEffect(() => {
    const { fabric } = window;
    const paddingX = 10;
    const paddingY = 10;

    // shortcut key bindings
    disposerCleaner(
      (() => {
        const combokeys = new Combokeys(window);
        // const combokeys = new Combokeys(canvas.htmlEle);
        combokeys.bind('esc', (evt) => {
          if (canvas.isPictureMode) {
            evt.stopPropagation();
            evt.preventDefault();
            ref.current.target.set({
              isPictureMode: false,
              isToolbarActive: false,
              left: 0,
              top: 0,
              width: 0,
              height: 0,
              opacity: 0,
            });
            canvas.isPictureMode = false;
            canvas && canvas.updateObject(ref.current.target);
            canvas.fire('mode:changed', {
              mode: 'Picture',
              value: !!canvas.isPictureMode,
              source: 'Picture',
            });
            forceUpdate();
            return false;
          }
          return false;
        });
        return () => {
          combokeys.detach();
        };
      })()
    );

    disposerCleaner(
      canvas.$on('object:modified', ({ target }) => {
        const { type } = target;

        if (_.get(target, 'isToolbar')) {
          if (type === 'Picture') {
            forceUpdate();
          }
        }
      })
    );

    disposerCleaner(
      canvas.$on('mode:changed', (event) => {
        if (isActivateMode(event, 'Picture')) {
          // activate mode
          canvas.isPictureMode = true;
          ref.current.target.set({
            isPictureMode: true,
            isToolbarActive: true,
          });
          canvas.requestRenderAll();
          forceUpdate();
        } else if (isDeactivateMode(event, 'Picture')) {
          // deactivate mode
          canvas.isPictureMode = false;
          ref.current.target.set({
            isPictureMode: false,
            isToolbarActive: false,
          });
          ref.current.target.set({
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            opacity: 0,
          });
          canvas.requestRenderAll();
          forceUpdate();
        }
      })
    );

    disposerCleaner(
      canvas.$on('mouse:up', (evt) => {
        if (canvas.isPictureMode) {
          const left = _.get(evt, 'absolutePointer.x');
          const top = _.get(evt, 'absolutePointer.y');
          const imgSrc = _.get(ref.current, 'form.values.imgSrc.0.url', '');
          const file = _.get(ref.current, 'form.values.imgSrc.0');
          console.log('asdasddsasda', imgSrc, file);
          // 'http://fabricjs.com/assets/pug_small.jpg'
          const Picture = new fabric.Picture({
            imgSrc,
            owner_id: canvas.getOwnerId(),
            left,
            top,
            radius: 4,
            colorType: ref.current.target.colorType,
            strokeWidth: ref.current.target.strokeWidth,
            charSpacing: ref.current.target.charSpacing,
            fontSize: ref.current.target.fontSize,
            paddingX,
            paddingY,
            width: 240,
            height: 200,
          });
          canvas.add(Picture);
          setTimeout(() => {
            canvas.isPictureMode = false;
          }, 13);
          canvas.fire('mode:changed', { mode: 'Picture', source: 'Picture', value: false });
          canvas.defaultCursor = 'default';
          ref.current.target.set({
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            opacity: 0,
          });
          ref.current.target.sendToBack();
          ref.current.Picture = Picture;
        }
      })
    );
    disposerCleaner(
      canvas.$on('mouse:move', (evt) => {
        if (canvas.isPictureMode) {
          const left = _.get(evt, 'absolutePointer.x');
          const top = _.get(evt, 'absolutePointer.y');

          ref.current.target.set({
            left,
            top,
            width: 240,
            height: 200,
            opacity: 0.3,
          });
          ref.current.target.setCoords();
          ref.current.target.bringToFront();
          canvas.requestRenderAll();
        }
      })
    );
  }, []);

  const visible = getVisible();
  _.assign(ref.current, { visible });

  const [isOpen, $isOpen] = React.useState(false);
  const [value, $value] = React.useState('');
  const [fileList, $fileList] = React.useState([]);
  const [uploading, $uploading] = React.useState(false);

  _.assign(ref.current, {
    isOpen,
    $isOpen,
    value,
    $value,
    fileList,
    $fileList,
    uploading,
    $uploading,
  });

  return (
    <Upload
      key="leftButton"
      {...AssetModelFormatter.getUploaderPropsEx(form)({
        fieldName: 'imgSrc',
        onFinish: ({ url }, file) => {
          const Picture = _.get(ref.current, ['pictureMapping', file.uid]) || ref.current.Picture;
          if (url && Picture) {
            Picture.setSrc(url);
          }
        },
        onProgress: ({ percent }, file) => {
          if (ref.current.Picture) {
            _.set(ref.current, ['pictureMapping', file.uid], ref.current.Picture);
          }
        },
        multiple: false,
      })}
      showUploadList={false}
      accept="image/*"
      onChange={(file) => {
        if (file) {
          ref.current.Picture = null;
          canvas.isPictureMode = !canvas.isPictureMode;
          canvas.fire('mode:changed', {
            mode: 'Picture',
            source: 'Picture',
            value: !!canvas.isPictureMode,
          });
        }
      }}
    >
      <MenuItem
        type={canvas.isPictureMode ? 'primary' : 'text'}
        getPopupContainer={(node) => node && node.parentElement}
        visible={canvas.isPictureMode}
        trigger={['click']}
        onClick={() => {
        }}
        overlay={() => {
          return (
            <div className="flex flex-row bg-white w-full">
              <div>
                <ColorInput
                  target={ref.current.target}
                  canvas={canvas}
                  open
                  dropdownAlign={{
                    offset: [-17, -62],
                  }}
                />
              </div>
            </div>
          );
        }}
        buttonsRender={([leftButton, rightButton]) => [
          <Tooltip
            title={
              <div className="space-x-2">
                <span>{i18n.t('Whiteboard.toolbar.pictureTooltip')}</span>
                <span className="">I</span>
              </div>
            }
            key="leftButton"
          >
            {leftButton}
          </Tooltip>,
          rightButton
        ]}
        icon={gstyles.icons({ name: 'arrow-down', size: 16, fill: gstyles.colors.white500 })}
        placement="topLeft"
      >
        <span>{gstyles.icons({ name: 'attachment', size: 20, fill: gstyles.colors.white500 })}</span>
      </MenuItem>
    </Upload>
  );
};

export default displayName(Index);
