import _ from 'lodash';
import UserIdentity from '@uz/unitz-components-web/UserIdentity';

// const backgroundColorMap = {
//   '#E05932': '#F5BFB1',
//   '#F6C844': '#FCEA89',
//   '#4DA560': '#A5E09D',
//   '#7199ED': '#B6D0FB',
//   '#6C50F6': '#E3C1FB',
//   '#C67F3E': '#E4C38F',
//   '#545454': '#CFD3D9',
//   '#FFFFFF': '#F8F8F8',
// };

const config = (fabric) => {
  fabric.Picture = fabric.util.createClass(fabric.Rect, {
    type: 'Picture',
    text: '',
    imgSrc: '',
    paddingX: 0,
    paddingY: 0,
    lockScalingX: false,
    lockScalingY: false,
    showAuthor: true,
    _prevObjectStacking: null,
    _prevAngle: 0,

    recalcTextPosition() {
      // const fill = _.get(backgroundColorMap, _.get(this, 'colorType'), _.get(this, 'colorType')) || '#F5BFB1';
      // const fill = 'transparent';
      const fill = 'black';
      const sin = Math.sin(fabric.util.degreesToRadians(this.angle));
      const cos = Math.cos(fabric.util.degreesToRadians(this.angle));
      const newLeft = cos * this.paddingX - sin * this.paddingY;
      const rectLeftTop = this.getPointByOrigin('left', 'top');
      const rectHeight = this.height * this.scaleY;
      const rectWidth = this.width * this.scaleX;

      const authorTextHeight = 12;
      const containerProps = {
        fill,
        stroke: fill,
      };
      _.assign(containerProps, {
        height: Math.max(200, 0),
      });
      this.set(containerProps);
      this.setCoords();

      const authorTextProps = {
        left: rectLeftTop.x + newLeft,
        top:
          rectLeftTop.y +
          sin * (rectWidth - this.paddingX - authorTextHeight) +
          cos * (rectHeight - this.paddingY - authorTextHeight),
        ...(this.showAuthor ? { opacity: 1 } : { opacity: 0 }),
      };
      const authorText = this.getAuthorText();
      authorText.set(authorTextProps);
      authorText.setCoords();

      if (this.width && this.height && this.picture) {
        this.fitPicture();
      }
    },

    setSrc(src) {
      if (this.imgSrc !== src) {
        this.imgSrc = src;
        if (this.picture) {
          this.picture.setSrc(src, (myImg) => {
            const _orgWidth = myImg.width;
            const _orgHeight = myImg.height;
            this.picture.set({
              _orgWidth,
              _orgHeight,
            });
            this.fitPicture();
            this.picture.canvas.renderAll();
          });
        }
      }
    },

    fitPicture() {
      if (this.picture) {
        const _orgWidth = this.picture._orgWidth;
        const _orgHeight = this.picture._orgHeight;
        if (!_orgHeight || !_orgHeight) {
          return;
        }
        const scaleX = (this.width * this.scaleX) / _orgWidth;
        const scaleY = (this.height * this.scaleY) / _orgHeight;
        const attr = {
          left: this.left,
          top: this.top,
          angle: this.angle,
          scaleX,
          scaleY,
        };
        this.picture.set(attr);
        this.picture.setCoords();
      }
    },

    getCanvas(evt) {
      let rtn;
      rtn = _.get(evt, 'target.canvas');
      if (rtn) return rtn;
      return this.canvas;
    },
    getAuthorText() {
      const canvas = this.getCanvas();
      let authorText = this.authorText;
      if (canvas) {
        authorText = _.find(canvas.getObjects(), { id: `${this.id}_2` }) || authorText;
      }
      return authorText;
    },

    bringToFront() {
      if (this.canvas) {
        this.canvas?.bringToFront?.(this);
        this.picture && this.canvas?.bringToFront?.(this.picture);
        this.authorText && this.canvas?.bringToFront?.(this.authorText);
      }
    },

    toObject() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        ..._.pick(this, [
          'paddingX',
          'paddingY',
          'strokeWidth',
          'charSpacing',
          'fontSize',
          'colorType',
          'textAlign',
          'showAuthor',
          'imgSrc',
        ]),
      });
    },

    initialize(options, object) {
      options = options || object || {};
      this.callSuper('initialize', options);
      // this.lockUniScaling = true;
      // this.setControlsVisibility({
      //   mt: false,
      //   mb: false,
      //   ml: false,
      //   mr: false,
      //   bl: false,
      //   br: false,
      //   tl: false,
      //   tr: false,
      // });

      const authorText = '';
      const authorColor = '#7F7F7F';
      this.authorText = new fabric.Text(`${authorText}`, {
        fontSize: 12,
        charSpacing: 10,
        strokeWidth: 1,
        text: authorText,
        fill: authorColor,
        stroke: authorColor,
        selectable: false,
        evented: false,
        isLocal: true,
        id: `${this.id}_2`,
      });

      if (this.width && this.height) {
        const imgSrc = this.imgSrc || 'https://via.placeholder.com/300.png';
        // const imgSrc = this.imgSrc;
        fabric.Image.fromURL(imgSrc, (myImg) => {
          const _orgWidth = myImg.width;
          const _orgHeight = myImg.height;
          const img1 = myImg.set({
            _orgWidth,
            _orgHeight,
            selectable: false,
            evented: false,
            isLocal: true,
            id: `${this.id}_3`,
          });
          this.picture = img1;
          this.fitPicture();
          const canvas = this.getCanvas();
          canvas && canvas.add(this.picture);
          if (imgSrc !== this.imgSrc) {
            this.picture.setSrc(this.imgSrc);
          }
        });
      }

      UserIdentity.getDisplayName(this.owner_id).then((val) => {
        this.authorText.set({
          text: `${val}`,
        });
      });

      const getCanvas = (evt) => {
        let rtn;
        rtn = _.get(evt, 'target.canvas');
        if (rtn) return rtn;
        return this.canvas;
      };

      this.recalcTextPosition();
      this.on('moving', () => {
        this.recalcTextPosition();
      });
      this.on('rotating', () => {
        this.authorText.rotate(this.authorText.angle + this.angle - this._prevAngle);
        this.recalcTextPosition();
        this._prevAngle = this.angle;
      });
      this.on('scaling', () => {
        this.recalcTextPosition();
      });
      this.on('added', function(evt) {
        const canvas = getCanvas(evt);
        if (!canvas) return;
        canvas.add(this.authorText);
      });
      this.on('removed', function(evt) {
        const canvas = getCanvas(evt);
        if (!canvas) return;
        canvas.remove(this.authorText);
        this.picture && canvas.remove(this.picture);
      });
      this.on('mousedown:before', function(evt) {
        const canvas = getCanvas(evt);
        if (!canvas) return;
        this._prevObjectStacking = canvas.preserveObjectStacking;
        canvas.preserveObjectStacking = true;
      });
      // this.on('deselected', function(evt) {
      //   const canvas = getCanvas(evt);
      //   if (canvas) {
      //     // canvas.preserveObjectStacking = this._prevObjectStacking;
      //   }
      // });
      this.on('selected', function() {
        this.bringToFront();
        this.recalcTextPosition();
      });

      this.on('modified', function() {
        this.recalcTextPosition();
      });
      this.on('object:modified', function() {
        this.recalcTextPosition();
      });
    },
  });

  fabric.Picture.fromObject = function(object, callback) {
    return fabric.Object._fromObject('Picture', object, callback, {});
  };
};

export default config;
